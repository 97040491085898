




import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatRelative, parseISO } from 'date-fns';

@Component({})
export default class TimeComponent extends Vue {
  @Prop() date!: Date | string;

  private getRelative(date: Date | string): string {
    if (typeof date === 'string') {
      return formatRelative(parseISO(date), new Date());
    } else {
      return formatRelative(date, new Date());
    }
  }
}
