var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative inline-block text-left" }, [
    _c("div", [
      _c(
        "button",
        {
          staticClass:
            "inline-flex items-center px-4 py-2 font-bold text-gray-800 rounded hover:bg-gray-100 display-none focus:outline-none",
          on: {
            click: function($event) {
              _vm.show = !_vm.show
            },
            blur: function($event) {
              _vm.show = false
            }
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "ellipsis-v"] } })],
        1
      )
    ]),
    _vm.show
      ? _c(
          "div",
          {
            staticClass:
              "absolute right-0 z-40 w-56 mt-2 origin-top-right rounded-md shadow-lg"
          },
          [
            _vm.slotExists()
              ? _c(
                  "div",
                  {
                    staticClass: "py-1 bg-white rounded-md shadow-xs",
                    on: {
                      mousedown: function($event) {
                        return $event.preventDefault()
                      }
                    }
                  },
                  [_vm._t("default")],
                  2
                )
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }