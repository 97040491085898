




























import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class DropDownComponent extends Vue {
  private show = false;

  private slotExists() {
    return this.show && !!this.$slots.default && !!this.$slots.default[0];
  }
}
